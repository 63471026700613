import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState, memo } from "react";
import AuthContext from "../../store/auth-context";
import { t } from "../../locales/i18n";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import Carousel from "../Carousel/Carousel";
import FadeIn from "../FadeIn/FadeIn";
import Helpers from "../../utils/Helpers";
import "../../index.css";

const PRIMARY_BANNER_IMAGES = [
  { name: "first item", src: images.nail7 },
  { name: "3rd image", src: images.nail11 },
  { name: "4th image", src: images.nail10 },
    { name: "2nd item", src: images.nail8 },
];

const PrimaryBanner = (props) => {
  const {} = props || {};

  return (
    <div className={classes.primaryBanner}>
      <FadeIn>
        <Carousel
          animationHandler="fade"
          swipeable={false}
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
          renderIndicator={() => null}
          interval={5000}
        >
          {Helpers.isArrayAvailable(PRIMARY_BANNER_IMAGES)
            ? PRIMARY_BANNER_IMAGES.map((item, index) => {
                const { name, src } = item || [];
                return (
                  <div key={`${name + index}`} className={classes.carouselItem}>
                    <img alt={name} src={src} />
                  </div>
                );
              })
            : null}
        </Carousel>
      </FadeIn>

      <FadeIn speed={1.5} delay={0.8}>
        <div className={classes.carouseTextContainer}>
          <h3 className={`cursive-text`}>{"Welcome to"}</h3>
          <h1 className={classes.shopNameTitle}>{NAIL_SHOP_INFO.name}</h1>
          <BookNowBtn
            onClick={() => window.open("tel:" + NAIL_SHOP_INFO.tel, "_self")}
            style={classes.callNowBtn}
            title={"CALL NOW"}
          />
        </div>
      </FadeIn>
    </div>
  );
};

export default memo(PrimaryBanner);
