import React from "react";
import classes from "./BookNowBtn.module.css";
import { images } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import '../../index.css'

const BookNowBtn = (props) => {
  const navigate = useNavigate();
  const { isShowArrow = false, title = "BOOK APPOINTMENT", style, type='button' } = props;

  const onClickBookNow = () => {
    if (props?.onClick) {
      props?.onClick?.();
      return;
    }
    window.open(NAIL_SHOP_INFO.bookingUrl);
  };

  return null

  return (
    <button type={type} className={`${classes.button} ${style}`}>
      <div
        onClick={onClickBookNow}
        className={`${classes.bookNowBtn} ${props?.style}`}
      >
        <h3 className={classes["change-color-on-hover"]}>{title}</h3>
        {isShowArrow ? (
          <img alt={"right-arrow"} src={images.leftArrow} />
        ) : null}
      </div>
    </button>
  );
};

export default BookNowBtn;
