export const images = {
  flagUk: require("./flag-uk.png"),
  flagVn: require("./flag-vn.png"),
  fbIcon: require("./fb-icon.png"),
  messengerIcon: require("./messenger-icon.png"),
  instaIcon: require("./insta-icon.png"),
  pricing: require("./pricing.jpg"),
  nailService: require("./nail-service.png"),
  nailService2: require("./nail-service2.png"),
  nailService3: require("./nail-service3.png"),
  nailService4: require("./nail-service4.png"),
  leftArrow: require("./left-arrow.png"),
  nail1: require("./nail1.jpg"),
  nail2: require("./nail2.jpg"),
  nail4: require("./nail4.jpg"),
  nail3: require("./nail3.jpg"),
  nail5: require("./nail5.jpg"),
  nail6: require("./nail6.jpg"),
  nail7: require("./nail7.jpg"),
  nail8: require("./nail8.jpg"),
  nail9: require("./nail9.jpg"),
  nail10: require("./nail10.jpg"),
  nail11: require("./nail11.jpg"),
  header_primary: require("./header_primary.jpg"),
  header_primary02: require("./header_primary02.jpg"),
  header_primary03: require("./header_primary03.jpg"),
  shop_logo: require("./shop-logo.jpg"),
  nail_shop01: require("./nail_shop01.jpg"),
  nail_shop02: require("./nail_shop02.jpg"),
  nail_shop03: require("./nail_shop03.jpg"),
  nail_shop04: require("./nail_shop04.jpg"),
  nail_shop05: require("./nail_shop05.jpg"),
  nail_shop06: require("./nail_shop06.jpg"),
  nail_shop07: require("./nail_shop07.jpg"),
  nail_shop09: require("./nail_shop09.jpg")
};
