import { useEffect, useState, memo } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${(props) => props.speed}s ease-in-out;
  animation-delay: ${(props) => props.delay}s;
  animation-fill-mode: forwards;
  display: flex;
`;

const FadeIn = ({ children, speed = 1.2, delay = 0 }) => {
  return (
    <Container speed={speed} delay={delay}>
      {children}
    </Container>
  );
};

export default memo(FadeIn);
